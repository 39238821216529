import 'bootstrap/dist/js/bootstrap.bundle.min'
import '@vuepic/vue-datepicker/dist/main.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import './assets/css/main.css'
import './assets/css/datepicker.css'
import './assets/css/quill.css'

import { createApp } from 'vue'
import { vMaska } from "maska"

import App from './App.vue'
import router from './router'
import VueDatePicker from '@vuepic/vue-datepicker';
import VueApexCharts from 'vue3-apexcharts'

const app = createApp(App)

app.use(router)
app.use(VueApexCharts)
app.directive('maska', vMaska)
app.component('VueDatePicker', VueDatePicker);

app.provide('sysapp', {
  name: import.meta.env.VITE_APP_NAME ?? 'Metaprocesso',
  desc: import.meta.env.VITE_APP_DESC ?? 'Gestão de Compras Públicas',
  copy: import.meta.env.VITE_APP_COPY ?? 'OctusPi Development 2024',
  url: import.meta.env.VITE_APP_URL ?? 'localhost',
  version: import.meta.env.VITE_APP_VERSION ?? '1.0.0 alpha'
})

app.mount('#app')
